export const EL = {
  Orders: "Παραγγελίες",
  Payed: "Πληρωμένη",
  Payed_plural: "Πληρωμένες",
  Pending: "Σε εκκρεμότητα",
  All: "Όλα",
  All_fem: "Όλες",
  All_plural_male: "Όλοι",
  Canceled: "Ακυρωμένη",
  Canceled_plural: "Ακυρωμένες",
  Cancelled: "Ακυρωμένη",
  Cancelled_plural: "Πλήθος ακυρωμένων",
  Cancelled_Revenue_plural: "Ακυρωμένες",
  Sales: "Πωλήσεις",
  live_orders_table: "Live Παραγγελιες",
  since_yesterday: "Απο εχθές",
  date_submitted: "Ημερομηνια",
  table: "Τραπέζι",
  Tables: "Τραπέζια",
  price: "Τιμή",
  Quantity: "Ποσότητα",
  quantity: "Ποσότητα",
  Description: "Περιγραφή",
  status: "Κατάσταση",
  View: "Προβολή",
  Filters: "Φίλτρα",
  From: "Από",
  To: "Εώς",
  Save: "Αποθήκευση",
  Reset: "Επαναφορά",
  Cancel: "Ακύρωση",
  Gift: "Κέρασμα",
  Overview: "Επισκόπηση",
  Edit_Charts: "Επεξεργασία Γραφημάτων",
  Group_By: "Ταξινόμηση ανά",
  Day: "Ημέρα",
  Week: "Εβδομάδα",
  Month: "Μήνας",
  Year: "Χρόνος",
  Hello: "Καλησπέρα",
  Hello_no_specialchars: "Καλησπερα",
  Dashboard: "Πίνακας Ελέγχου",
  Analytics: "Στατιστικά",
  Table_Management: "Διαχείριση Τραπεζιών",
  Menu_Management: "Διαχείριση Μενού",
  Categories: "Κατηγορίες",
  Category: "Κατηγορία",
  Subcategories: "Υποκατηγορίες",
  Subcategory: "Υποκατηγορία",
  Products: "Προιόντα",
  Product: "Προιόν",
  Variations: "Χαρακτηριστικά",
  variants: "Χαρακτηριστικά",
  Variation: "Χαρακτηριστικό",
  Select_variation: "Επιλογή Χαρακτηριστικού",
  Variation_Options: "Επιλογές Χαρακτηριστικών",
  Options: "Επιλογές",
  Floors: "Χωροι",
  Settings: "Ρυθμίσεις",
  Floor: "Χωρος",
  User_Management: "Διαχείριση Χρηστών",
  General: "Γενικά",
  Permissions_Management: "Διαχείριση Δικαιωμάτων",
  Printers_Management: "Διαχείριση Εκτυπωτών",
  Printers_Settings: "Ρυθμίσεις Εκτυπωτών",
  Setting: "Ρύθμιση",
  View_Order: "Προβολή Παραγγελίας",
  Comments: "Σχόλια",
  No_comments: "Δεν υπάρχουν σχόλια",
  notes: "Σχόλια",
  DateTime: "{{date, LL HH:mm:ss}}",
  Date: "{{date, LL}}",
  Table_Actions: "Επιλογές Τραπεζιού",
  Actions: "Επιλογές",
  Enabled: "Ενεργό",
  Cust_label: "Εμφάνιση σε χρήστη",
  New_Order: "Νέα Παραγγελία",
  Pay_Items: "Πληρωμή",
  Transfer: "Μεταφορά",
  Cart: "Καλάθι",
  Type: "Τύπος",
  Single: "Μονός",
  Multiple: "Πολλαπλός",
  cart_empty_msg: "Το καλάθι σας είναι άδειο.",
  cart_inventory_invalid:
    "Οι ποσότητες που επιλέξατε δεν είναι διαθέσιμες. Προσαρμόστε στις διαθέσιμες ποσότητες και ξαναπροσπαθήστε.",
  Checkout: "Αποστολή",
  Out_of_stock: "Εκτός αποθέματος",
  Add_comments: "Προσθέστε σχόλια",
  Add: "Προσθήκη",
  Total: "Σύνολο",
  Order_Total: "Σύνολο παραγγελίας",
  Subtotal: "Υποσύνολο",
  Before_discount: "Προ έκπτωσης",
  Select_table: "Επιλογή τραπεζιού",
  Terms_of_service: "Όροι χρήσης",
  About_us: "Σχετικά με εμάς",
  Support: "Υποστήριξη",
  Logout: "Αποσύνδεση",
  Top_10_Products: "10 Δημοφιλέστερα Προιόντα",
  Trending: "Τάσεις",
  User_Sales: "Πωλήσεις Χρήστη",
  User_Sales_Descr: "Πωλήσεις ανά χρήστη",
  Product_Sales: "Πωλήσεις Προιόντος",
  Product_Sales_Descr: "Πωλήσεις ανά προιόν",
  Sales_Origin: "Προέλευση Πωλήσεων",
  Sales_Origin_Descr: "Πωλήσεις ανά προέλευση",
  Title: "Τίτλος",
  Chart_name: "Όνομα Γραφήματος",
  Top_10_Products_Descr: "Δημοφιλεστερα προιοντα την επιλεγμενη περιοδο",
  Trending_Products_Descr: "Προιοντα στις τασεις σημερα",
  Name: "Ονομα",
  name: "Ονομα",
  Delete_table: "Διαγραφή τρσαπεζιού",
  New_option: "Νέα Επιλογή Χαρακτηριστικού",
  Add_table: "Προσθήκη τρσαπεζιού",
  Edit_table: "Επεξεργασία Τραπεζιού",
  Edit_floor: "Επεξεργασία Χώρου",
  New_floor: "Δημιουργία Χώρου",
  Delete_floor_confirmation: "Είστε σίγουροι ότι θέλετε να διαγράψετε τον Χώρο",
  Delete_table_confirmation: "Είστε σίγουροι ότι θέλετε να διαγράψετε το Τραπέζι",
  Delete: "Διαγραφή",
  Edit: "Επεξεργασία",
  Image: "Εικόνα",
  Images: "Εικόνες",
  New_Category: "Νέα Κατηγορία",
  New_Subcategory: "Νέα Υποκατηγορία",
  New_Product: "Νέο Προιόν",
  New_Variation: "Νέο Χαρακτηριστικό",
  Parent_category: "Γονική Κατηγορία",
  Choose_category: "Επιλέξτε κατηγορία",
  Choose_image: "Επιλογή εικόνας",
  Select: "Επιλογή",
  Selected_options: "Επιλεγμένες επιλογές",
  Upload: "Μεταφόρτωση",
  Close: "Κλείσιμο",
  Remove: "Αφαίρεση",
  Yes: "Ναι",
  No: "Όχι",
  Edit_Category: "Επεξεργασία Κατηγορίας",
  Edit_Subcategory: "Επεξεργασία Υποκατηγορίας",
  Edit_Variation: "Επεξεργασία Χαρακτηριστικού",
  Edit_Product: "Επεξεργασία Προιόντος",
  Edit_Option: "Επεξεργασία Επιλογής Χαρακτηριστικού",
  Delete_category_msg: "Είστε σίγουροι οτι θέλετε να διαγράψατε την Κατηγορία",
  Delete_subcategory_msg: "Είστε σίγουροι οτι θέλετε να διαγράψατε την Υποκατηγορία",
  Delete_product_msg: "Είστε σίγουροι οτι θέλετε να διαγράψατε το Προιόν",
  Delete_variation_msg: "Είστε σίγουροι οτι θέλετε να διαγράψατε το Χαρακτηριστικό",
  Delete_option_msg: "Είστε σίγουροι οτι θέλετε να διαγράψατε την Επιλογή Χαρακτηριστικού",
  Delete_scenario_msg: "Είστε σίγουροι οτι θέλετε να διαγράψατε το Σενάριο",
  No_products_found: "Δεν βρέθηκαν προιόντα",
  VAT: "ΦΠΑ",
  Gross: "Μεικτά",
  Inventory_management: "Διαχείριση αποθέματος",
  No_variations_available: "Δεν υπάρχουν διαθέσιμα Χαρακτηριστικά",
  No_options_available: "Δεν υπάρχουν διαθέσιμες Επιλογές Χαρακτηριστικών",
  Organisation_name: "Όνομα Επιχείρησης",
  DOY: "ΔΟΥ",
  Address: "Διεύθυνση",
  City: "Πόλη",
  Postcode: "Ταχ. Κώδικας",
  Phone: "Τηλέφωνο",
  GEMH: "ΓΕΜΗ",
  AFM: "ΑΦΜ",
  Mydata_usr: "MyData Χρήστης",
  Mydata_key: "MyData Κλειδί",
  Printserver_key: "Κλειδί εκτύπωσης",
  End_of_day: "Τέλος ημέρας",
  Saving_successful: "Αποθήκευση επιτυχής.",
  Saving_error: "Αποθήκευση ανεπιτυχής.",
  User: "Χρήστης",
  Role: "Ρόλος",
  Enabled_male: "Ενεργός",
  Edit_user: "Επεξεργασία Χρήστη",
  Username: "Όνομα Χρήστη",
  Manager: "Υπεύθυνος",
  manager: "Υπεύθυνος",
  Staff: "Προσωπικό",
  staff: "Προσωπικό",
  Select_Printer: "Επιλέξτε εκτυπωτή",
  Edit_Printer: "Επεξεργασία εκτυπωτή",
  Active_male: "Ενεργός",
  Active_fem: "Ενεργή",
  Active_sub: "Ενεργό",
  Inactive_sub: "Ανενεργό",
  Is_receipt_printer: "Εκτύπωση Παραστατικών",
  Menu_management_categories: "Διαχείριση Μενού - Κατηγορίες",
  Menu_management_new_category: "Διαχείριση Μενού - Νέα Καρηγορία",
  Menu_management_edit_category: "Διαχείριση Μενού - Επεξεργασία Κατηγορίας",
  Menu_management_subcategories: "Διαχείριση Μενού - Υποκατηγορίες",
  Menu_management_new_subcategory: "Διαχείριση Μενού -  Νέα Υποκατηγορία",
  Menu_management_edit_subcategory: "Διαχείριση Μενού - Επεξεργασία Υποκατηγορίας",
  Menu_management_products: "Διαχείριση Μενού - Προιόντα",
  Menu_management_new_product: "Διαχείριση Μενού - Νέο Προιόν",
  Menu_management_edit_product: "Διαχείριση Μενού - Επεξεργασία Προιόντος",
  Menu_management_variations: "Διαχείριση Μενού - Χαρακτηριστικά",
  Menu_management_variation_options: "Διαχείριση Μενού - Επιλογές Χαρακτηριστικών",
  Settings_general: "Ρυθμίσεις - Γενικά",
  Settings_user_management: "Ρυθμίσεις - Διαχείριση Χρηστών",
  Settings_permissions_management: "Ρυθμίσεις - Διαχείριση Δικαιωμάτων",
  Settings_printer_management: "Ρυθμίσεις - Διαχείριση Εκτυπωτών",
  Settings_edit_printer: "Ρυθμίσεις - Επεξεργασία Εκτυπωτή",
  New_order_select_category: "Νέα Παραγγελία - Επιλογή Κατηγορίας",
  New_order_select_subcategory: "Νέα Παραγγελία - Επιλογή Υποκατηγορίας",
  New_order_select_procucts: "Νέα Παραγγελία - Επιλογή Προιόντων",
  New_order_select_product: "Νέα Παραγγελία - Επιλογή Προιόντος",
  Settings_print_scenarios: "Ρυθμίσεις - Σενάρια εκτύπωσης",
  true_val: "Ναί",
  false_val: "Όχι",
  Choose: "Επιλέξτε",
  Printer_Encoding: "Κωδικοποίηση Εκτυπωτή",
  Column_width: "Πλάτος Στήλης",
  No_Products: "Δεν υπάρχουν προιόντα",
  Recommended_resolutions: "Προτεινόμενες διαστάσεις",
  Rank: "Σειρά",
  Rank_label: "Σειρά εμφάνισης",
  Rank_product_field_hint: "Σειρά εμφάνισης προιόντος στον Κατάλογο",
  Rank_error_message: "H 'Σειρά' δεν μπορεί να είναι <= 0",
  Price_invalid_msg: "Λανθασμένη τιμή",
  Name_invalid_msg: "Το όνομα δεν μπορεί να είναι κενό",
  Address_invalid_msg: "Η διεύθυνση δεν μπορεί να είναι κενή",
  Vat_number_invalid_msg: "Το ΑΦΜ δεν μπορεί να είναι κενό",
  Doy_invalid_msg: "Η ΔΟΥ δεν μπορεί να είναι κενή",
  City_invalid_msg: "Η πόλη δεν μπορεί να είναι κενή",
  Postcode_invalid_msg: "Ο ΤΚ δεν μπορεί να είναι κενός",
  Phone_invalid_msg: "Το τηλέφωνο δεν μπορεί να είναι κενό",
  Gemi_invalid_msg: "Το ΓΕΜΗ δεν μπορεί να είναι κενό",
  Payment_method: "Τρόπος πληρωμής",
  Cash: "Μετρητά",
  Card: "Κάρτα",
  Payment_method_enabled: "Καθορισμός τρόπου πληρωμής",
  Receipt_groups: "Ομάδες εκτύπωσης",
  Internal_receipt_settings: "Ρυθμίσεις αποκόμματος",
  Internal_receipt_setting: "Απόκομα",
  Receipt_group: "Ομάδα",
  Net_Sales: "Καθαρές πωλήσεις",
  Last_30_days: "Τελευταίες 30 μέρες",
  Cancel_Gift: "Ακύρωση / Κέρασμα",
  Discount: "Έκπτωση",
  Gift_order: "Κέρασμα παραγγελίας",
  Cancellation_reason: "Λόγος ακύρωσης",
  Cancel_order: "Ακύρωση παραγγελίας",
  Gift_reason: "Λόγος κεράσματος",
  Enter_gift_reason: "Λόγος κεράσματος (προεραιτικό)",
  Enter_cancellation_reason: "Λόγος ακύρωσης (προεραιτικό)",
  Gift_confirmation_message: "Είστε σίγουροι ότι θέλετε να κεράσετε προιόντα αξίας:",
  Discard: "Άκυρο",
  Submit: "Υποβολή",
  Reload: "Ανανέωση",
  Reloading: "Ανανέωση",
  Average_Revenue_Per_Customer: "Ανά πελάτη",
  Gifted_Orders: "Κερασμένες",
  Amount_of_customers: "Πλήθος πελατών",
  Customers_of_new_order_msg: "Πλήθος πελατών παραγγελίας",
  Customers: "Πελάτες",
  Define_customers_per_order: "Καθορισμός πλήθους πελατών",
  Define_discounts_enabled: "Λειτουργία έκπτωσης",
  Key_metrics: "Κύρια στατιστικά",
  Key_metrics_subtitle: "Κυρια στατιστικα για την επιχειριση σας",
  Quantity_support: "Μεταβλητή ποσότητα",
  Pending_Orders: "Εκκρεμείς",
  Pending_Revenue: "Εκκρεμείς πωλήσεις",
  No_Categories_found: "Δεν βρέθηκαν κατηγορίες",
  Total_Discount: "Έκπτωση",
  Translation_cannot_be_negative: "Το ποσό έκπτωσης δεν μπορεί να είναι αρνητικός αριθμός",
  Translation_cannot_be_larger_than_order: "Το ποσό έκπτωσης δεν μπορεί να είναι μεγαλύτερο του συνόλου παραγγελίας",
  Discounted_Orders: "Με έκπτωση",
  discounted: "Με έκπτωση",
  cancelled: "Ακυρωμένες",
  pending: "Σε εκκρεμότητα",
  payed: "Πληρωμένες",
  gifted: "Κερασμένες",
  Is_open_table_printer: "Εκτύπωση λογαριασμών",
  Print_Receipts: "Ετύπωση απόδειξης",
  Print_Receipts_on_order_submission: "Κατά την υποβολή παραγγελίας",
  Print_Receipts_on_order_payment: "Κατά την πληρωμή παραγγελίας",
  Error: "Σφάλμα",
  Invoice_submission_error: "Σφάλμα παραστατικού",
  Try_again: "Επανάληψη",
  Reprint: "Επανεκτύπωση",
  Print: "Εκτύπωση",
  Submit_invoice: "Έκδοση Παραστατικού",
  Submit_invoice_short: "Έκδοση",
  Internal: "Εσωτερικό",
  OpenTable: "Λογαριασμός",
  OpenTable_invoice: "Δελτίο Παραγγελίας",
  GR_Receipt: "Απόδειξη λιανικής",
  Mydata_status: "Κατάσταση MyData",
  mydata_status_sent: "Απεστάλη",
  mydata_status_not_sent: "Δεν απεστάλη",
  mydata_status_sent_with_error: "Απεστάλη με αδυναμία σύνδεσης με πάροχο",
  Error_fetching_settings_msg: "Αδυναμία λήψης ρυθμίσεων. Παρακαλώ ανανεώστε την σελίδα.",
  Category_invalid_msg: "Η κατηγορία δεν μπορεί να είναι κενή",
  Return_inventory: "Επιστροφή αποθέματος",
  Cancel_inventory_message:
    "Κάποια απο τα προιόντα που επιλέξατε έχουν διαχείριση αποθέματος. Τι θέλετε να γίνει στην αποθήκη μετά την ακύρωση;",
  Cancellation_Mark: "Mark Πιστωτικού",
  Cancellation_UID: "UID Πιστωτικού",
  Cancellation_Authcode: "Authcode Πιστωτικού",
  Receipt_id: "Αρ. Παραστατικού",
  Cancel_receipt_id: "Αρ. Πιστωτικού",
  cancelled_invoice: "Πιστωτικό στοιχείο",
  Resubmit: "Επαναπαραγγελία",
  Exceeded_inventory_for_product: "Επιλέξατε μεγαλύτερη ποσότητα απο την διαθέσιμη για το προιόν",
  Force_submit: "Επιβολή υποβολής",
  Submit_invoice: "Έκδοση παραστατικού",
  Without_Invoice: "Χωρίς Παραστατικό",
  Retail_Invoice: "Απόδειξη Λιανικής",
  Inventory_invalid_msg: "Η ποσότητα δεν μπορεί να είναι αρνητικός αριθμός",
  Table_filter_msg: "Φίλτρο τραπεζιών",
  Select_categories: "Επιλογη κατηγοριών",
  Calculate_change: "Υπολογισμός ρέστων",
  Enter_cash: "Εισαγωγή μετρητών",
  Change: "Ρέστα",
  Print_scenarios: "Σενάρια Εκτύπωσης",
  No_print_scenarios_msg: "Δεν βρέθηκαν σενάρια εκτύπωσης",
  New_Scenario: "Νέο σενάριο",
  Edit_Scenario: "Επεξεργασία σεναρίου",
  Search: "Αναζήτηση",
  Search_product: "Αναζήτηση προιόντος",
  Print_font_size: "Γραμματοσειρά εκτύπωσης",
  No_results_found: "Δεν βρέθηκαν αποτελέσματα",
  helperWholesale: "Ανεπίσημο τιμολόγιο",
  Invoice_Type: "Τύπος Παραστατικού",
  Customer: "Πελάτης",
  Customer_info: "Στοιχεία πελάτη",
  Business_name: "Επωνυμία",
  retail: "Απόδειξη λιανικής",
  opentable: "Δελτίο Παραγγελίας",
  Revenue: "Τζίρος",
  Sales_per_product: "Πωλήσεις ανά προιόν",
  Sales_per_category: "Πωλήσεις ανά κατηγορία",
  Show_label: "Εμφάνιση",
  Table_Summary: "Λογαριασμός",
  Table_Summary_print_error: "Σφάλμα κατά την εκτύπωση λογαριασμού",
  Table_Summary_print_success: "Ο λογαριασμός εκτυπώθηκε με επιτυχία",
  Discount_reason: "Αιτιολογία έκπτωσης",
  Invoiced_Revenue: "Τζίρος παραστατικών",
  Submit_All_Orders: "Υποβολή όλων των παραγγελιών",
  Submit_All_Orders_Warning: "Είστε βέβαιοι ότι θέλετε να ενεργοποιήσετε το κουμπί πανικού;",
  manual_cloud_cash_register_enabled_label: "Χειροκίνητα παραστατικά",
  Notification: "Ειδοποίηση",
  Notification_SENT_SUCCESSFULLY: "Επιτυχής αποστολή ειδοποίησης",
  Notification_permission_not_granted: "Δεν έχετε ενεργοποιήσει τις ειδοποιήσεις προσωπικού. Παρακαλώ ακολουθήστε",
  Ping_From: "Έχετε ειδοποίηση από",
  SELECT_USER_NOTIFY: "Επιλογή χρηστών προς ειδοποίηση",
  SELECT_ALL: "Επιλογή όλων",
  Notify: "Ειδοποίηση",
  Subscribe: "Εγγραφή",
  wholesale: "Τιμολόγιο Πώλησης",
  open_table: "Λογαριασμός",
  Series: "Σειρά",
  Invoice_sent: "Έχει παραστατικό",
  Invoice_from_parent_msg: "Απεστάλη απο γονική παραγγελία",
  error_invoice: "Παραστατικό με σφάλμα",
  Guides: "Οδηγίες",
  Guides_for_notifications: "Οδηγίες ενεργοποίησης κλήσης προσωπικού",
  Notification_guides_subtitle:
    "Για να ενεγοποιήσετε την λειτουργία κλήσης προσωπικού, θα πρέπει να έχετε πραγματοποιήσει τις παρακάτω ενέργειες ανάλογα την συσκευή.",
  Save_as_app_in_phone: "Αποθηκεύστε το DitoApp σαν εφαρμογή σε κινητό",
  Mobile_phone: "Κινητά τηλέφωνα",
  Computer: "Υπολογιστής",
  Allow_notifs_in_browser: "Ενεργοποιήστε ειδποιήσεις για το DitoApp σε υπολογιστή",
  Allow_notifs_in_phone: "Ενεργοποιήστε ειδποιήσεις για το DitoApp σε κινητό",
  How_to_save_as_app:
    "Πααρακάτω θα βρείτε οδηγίες για το πως να σώσετε το DitoApp σαν εφαρμογή σε κινητό ανάλογα με το λειτουργικό σας σύστημα:",
  Normal_notifs_allow_case:
    "Στις περισσότερες περιπτώσεις αφού αποθηκεύεσετε το DitoApp σαν εφαρμογή, όταν το ανοίξετε και κάνετε Login θα πρέπει αυτόματα να σας ζητείται να ενεργοποιήσετε τις ειδοποιήσεις όπως παρακάτω.",
  Notifs_fallback_case:
    "Εάν αυτό δεν συμβεί ή αρνηθείτε την πρόσβαση κατά λάθος, μπορείτε να ενεργοποιήσετε τις ειδοποιήσεις ακολουθώντας τις παρακάτω οδηγίες αλάνογα με το λειτουργικό σας σύστημα",
  Logout_request_message_notifs:
    "Μόλις ακολουθήσετε τις παρακάτω οδηγίες, παρακαλώ κάνετε logout και ξανά login, και θα πρέπει στο πάνω μέρος της εφαρμογής να βλέπετε το παρακάτω εικονίδιο",
  guides_to_enable_notifs: "τις οδήγιες για να συνδεθείτε",
  additional_notes: "Επιπρόσθετες σημειώσεις",
  no_macos_compatibility_msg: "Στην τωρινή έκδοση της λειτουργίας δεν λαμβάνονται ειδοποιήσεις σε υπολογιστές με MacOs",
  available_notif_users_msg:
    'Οι χρήστες που είναι διαθέσιμοι για αποστολή ειδοποίησης είναι μόνο τύπου "staff" και συνδεδεμένοι',
  no_notifs_logout_msg:
    "Εάν κάποιος χρήστης δεν επιθυμεί να είναι διαθέσιμος για λήψη ειδοποιήσεων (πχ εκτός βάρδιας) μπορεί να κάνει logout",
  refresh_if_no_user_msg:
    "Σε περίπτωση που κάποιος συνδεδεμένος staff χρήστης δεν εμφανίζεται στη λίστα χρηστών προς ειδοποίηση, κάνετε ανανέωση της σελίδας",
  VERSION_ERROR: "Μια νέα έκδοση της εφαρμογής είναι διαθέσιμη. Επανεκκινήστε το πρόγραμμα περιήγησής σας.",
  Course_stages: "Πάσο",
  Course_stage: "Πάσο",
  Course_stages_msg_success: "Επιτυχής αποστολή ειδοποίησης πάσου",
  Is_course_stages_printer: "Εκτύπωση πάσου",
  Is_fee: "Κρατικό τέλος",
  Select_Receipt_Type: "Επιλογή τύπου παραστατικού",
  Hide_total_on_receipt: "Απόκρυψη συνόλου",
  Cash_register_management: "Διαχείριση Ταμειακής",
  Pos_terminals: "Τερματικά POS",
  No_pos_terminals_msg: "Δεν βρέθηκαν τερματικά POS",
  New_pos_terminal: "Νέο τερματικό",
  Edit_pos_terminal: "Επεξεργασία τερματικού",
  Allow_print_sound_notif: "Ήχος ειδοποίησης",
  Tip: "Tips",
  Total_Tip: "Συνολικά tips",
  Failed_Find_Vat: "Αποτυχία εύρεσης πελάτη με αντίστοιχο αριθμό ΦΠΑ",
  Transform_pay_card: "Μετατροπή σε κάρτα",
  Transform_pay_cash: "Μετατροπή σε μετρητά",
  Refresh_analytics: "Ανανέωση στατιστικών",
  Bank_statement: "Τραπεζική κατάθεση",
  Order_type_external_src: "Τύπος παραγγελίας",
  Close_with_card: "Χωρίς διασύνδεση",
  Select_30_day_period: "Παρακαλώ επιλέξτε μόνο μια περίοδο 15 ημερών",
  add_extra_top_space: "Επιπλέον κενό στην κορυφή",
  add_extra_bottom_space: "Επιπλέον κενό στο κάτω μέρος",
};
