import io from "socket.io-client";

import {
  WS_EVENT_SUBMIT_ORDERS_CHANGE,
  WS_EVENT_JOIN,
  WS_EVENT_ORDERS_CHANGE,
  WS_EVENT_CHART_CHANGE,
  WS_EVENT_SUBMIT_SETTINGS_CHANGE,
  WS_EVENT_SETTINGS_CHANGE,
  WS_EVENT_USER_CHANGE,
  WS_EVENT_USER_CHANGE_START,
  BACKEND_BASE_URL,
} from "utilities/constants";

const socket = io(BACKEND_BASE_URL, { path: `/websocket`, transports: ["websocket", "polling"] });

export function joinOrdersWS(organisationId, username) {
  socket.emit(WS_EVENT_JOIN, { organisationId, username }, (error) => {
    if (error) {
      alert(error);
    }
  });
  socket.on("connect", () => {
    socket.emit(WS_EVENT_JOIN, { organisationId, username }, (error) => {
      if (error) {
        alert(error);
      }
    });
  });
}

export function subscribeToUserChangeWS(organisationId, onUserChange) {
  socket.on(WS_EVENT_USER_CHANGE, (args) => {
    if (args.organisationId === organisationId || args.organisationId === -1) {
      onUserChange();
    }
  });
}

export function broadCastUserChangeWS(organisationId) {
  socket.emit(WS_EVENT_USER_CHANGE_START, { organisationId }, (error) => {
    if (error) {
      alert(error);
    }
  });
}

export function broadCastOrdersChangeWS(organisationId) {
  socket.emit(WS_EVENT_SUBMIT_ORDERS_CHANGE, { organisationId }, (error) => {
    if (error) {
      alert(error);
    }
  });
}

export function subscribeToOrdersChangeWS(organisationId, onOrdersChange) {
  socket.on(WS_EVENT_ORDERS_CHANGE, (args) => {
    if (args.organisationId === organisationId || args.organisationId === -1) {
      onOrdersChange();
    }
  });
}

export function subscribeToChartsChangeWS(organisationId, onChartsChange) {
  socket.on(WS_EVENT_CHART_CHANGE, (args) => {
    if (args.organisationId === organisationId || args.organisationId === -1) {
      onChartsChange();
    }
  });
}

export function broadCastSettingsChangeWS(organisationId) {
  socket.emit(WS_EVENT_SUBMIT_SETTINGS_CHANGE, { organisationId }, (error) => {
    if (error) {
      alert(error);
    }
  });
}

export function subscribeToSettingsChangeWS(organisationId, onSettingsChange) {
  socket.on(WS_EVENT_SETTINGS_CHANGE, async (args) => {
    if (args.organisationId === organisationId || args.organisationId === -1) {
      await onSettingsChange();
    }
  });
}
