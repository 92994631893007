export const EN = {
  Orders: "Orders",
  Payed: "Paid",
  Payed_plural: "Paid",
  Pending: "Pending",
  All: "All",
  All_fem: "All",
  All_plural_male: "All",
  Canceled: "Cancelled",
  Canceled_plural: "Orders cancelled",
  Cancelled: "Cancelled",
  Cancelled_plural: "Orders cancelled",
  Cancelled_Revenue_plural: "Cancelled",
  Sales: "Sales",
  live_orders_table: "Live Orders",
  since_yesterday: "Since yesterday",
  date_submitted: "Date",
  table: "Table",
  Tables: "Tables",
  price: "Price",
  Quantity: "Quantity",
  Description: "Description",
  status: "Status",
  View: "View",
  Filters: "Filters",
  Single: "Single",
  Multiple: "Multiple",
  From: "From",
  To: "To",
  Save: "Save",
  Reset: "Reset",
  Cancel: "Cancel",
  Overview: "Overview",
  Edit_Charts: "Edit Charts",
  Type: "Type",
  Group_By: "Group By",
  Day: "Day",
  Week: "Week",
  Month: "Month",
  Year: "Year",
  Hello: "Hello",
  Hello_no_specialchars: "Hello",
  Dashboard: "Dashboard",
  Analytics: "Analytics",
  Table_Management: "Table Management",
  Menu_Management: "Menu Management",
  Categories: "Categories",
  Category: "Category",
  Subcategories: "Subcategories",
  Subcategory: "Subcategory",
  Products: "Products",
  Product: "Product",
  Variations: "Variations",
  Variation: "Variation",
  Select_variation: "Select variation",
  Variation_Options: "Variation Options",
  Floors: "Floors",
  Floor: "Floor",
  Settings: "Settings",
  General: "General",
  User_Management: "User Management",
  Permissions_Management: "Permissions Management",
  Printers_Management: "Printers Management",
  View_Order: "View Order",
  Comments: "Comments",
  User: "User",
  No_comments: "No comments",
  DateTime: "{{date, LL HH:mm:ss}}",
  Date: "{{date, LL}}",
  Table_Actions: "Table Actions",
  Actions: "Actions",
  New_Order: "New Order",
  Pay_Items: "Pay",
  Transfer: "Transfer",
  Cart: "Cart",
  cart_empty_msg: "Your shopping cart is empty.",
  cart_inventory_invalid:
    "Available inventory cannot satisfy current cart items. Please remove some items from the cart.",
  Checkout: "Checkout",
  Out_of_stock: "Out of stock",
  Add_comments: "Add comments",
  Add: "Add",
  Total: "Total",
  Subtotal: "Subtotal",
  Select_table: "Select table",
  Terms_of_service: "Terms Of Service",
  About_us: "About Us",
  Support: "Support",
  Logout: "Logout",
  Top_10_Products: "Top 10 Products",
  Top_10_Products_Descr: "Top Products ordered in selected period",
  Trending_Products_Descr: "Trending Products Today",
  Trending: "Trending",
  User_Sales: "User Sales",
  User_Sales_Descr: "User Sales in selected period",
  Product_Sales: "Product Sales",
  Product_Sales_Descr: "Product Sales in selected period",
  Sales_Origin: "Sales Origin",
  Sales_Origin_Descr: "Sales Origin in selected period",
  Title: "Title",
  Chart_name: "Chart Name",
  Name: "Name",
  Delete_table: "Delete Table",
  Add_table: "Add Table",
  Edit_table: "Edit Table",
  Edit_floor: "Edit Floor",
  New_floor: "New Floor",
  Delete_floor_confirmation: "Are you sure you want to delete the Floor",
  Delete_table_confirmation: "Are you sure you want to delete the Table",
  Delete: "Delete",
  Edit: "Edit",
  Image: "Image",
  Images: "Images",
  New_Category: "New Category",
  New_Subcategory: "New Subcategory",
  New_Product: "New Product",
  New_Variation: "New Variation",
  Choose_image: "Choose image",
  Choose_category: "Choose category",
  Choose_subcategory: "Choose subcategory",
  Parent_category: "Parent Category",
  Select: "Select",
  Selected_options: "Selected Options",
  Upload: "Upload",
  Close: "Close",
  Remove: "Remove",
  Yes: "Yes",
  No: "No",
  Cust_label: "Label",
  Edit_Category: "Edit Category",
  Edit_Subcategory: "Edit Subcategory",
  Edit_Variaton: "Edit Variation",
  Edit_Product: "Edit Product",
  Edit_Option: "Edit Option",
  Delete_category_msg: "Are you sure you want to delete the Category",
  Delete_subcategory_msg: "Are you sure you want to delete the Subcategory",
  Delete_product_msg: "Are you sure you want to delete the Product",
  Delete_variation_msg: "Are you sure you want to delete the Variation",
  Delete_option_msg: "Are you sure you want to delete the Variation Option",
  Delete_scenario_msg: "Are you sure you want to delete the Scenario",
  No_products_found: "No products were found.",
  VAT: "VAT",
  Gross: "Gross",
  Inventory_management: "Inventory Management",
  Options: "Options",
  No_variations_available: "No variations available",
  No_options_available: "No options available",
  New_option: "New Option",
  Organisation_name: "Organisation Name",
  DOY: "DOY",
  Address: "Address",
  City: "City",
  Postcode: "Postcode",
  Phone: "Phone",
  GEMH: "GEMH",
  AFM: "AFM",
  Mydata_usr: "MyData User",
  Mydata_key: "MyData Key",
  Printserver_key: "Print Key",
  End_of_day: "End of Day",
  Saving_successful: "Save was successful.",
  Saving_error: "Error while saving",
  Enabled: "Enabled",
  Enabled_male: "Enabled",
  Role: "Role",
  Edit_user: "Edit User",
  Manager: "Manager",
  Staff: "Staff",
  Select_Printer: "Select Printer",
  Edit_Printer: "Edit Printer",
  Active_male: "Active",
  Active_fem: "Active",
  Is_receipt_printer: "Prints Receipts",
  Menu_management_categories: "Menu Management - Categories",
  Menu_management_new_category: "Menu Management - New Category",
  Menu_management_edit_category: "Menu Management - Edit Category",
  Menu_management_subcategories: "Menu Management - Subcategories",
  Menu_management_new_subcategory: "Menu Management -  New Subcategory",
  Menu_management_edit_subcategory: "Menu Management - Edit Subcategory",
  Menu_management_products: "Menu Management - Products",
  Menu_management_new_product: "Menu Management - New Product",
  Menu_management_edit_product: "Menu Management - Edit Product",
  Menu_management_variations: "Menu Management - Variations",
  Menu_management_variation_options: "Menu Management - Variation Options",
  Settings_general: "Settings - General",
  Settings_user_management: "Settings - User Management",
  Settings_permissions_management: "Settings - Permission Management",
  Settings_printer_management: "Settings - Printer Management",
  Settings_edit_printer: "Settings - Edit Printer",
  Settings_print_scenarios: "Settings - Print Scenarios",
  New_order_select_category: "New Order - Select Category",
  New_order_select_subcategory: "New Order - Select Subcatgory",
  New_order_select_procucts: "New Order - Select Products",
  New_order_select_product: "New Order - Select Product",
  true_val: "True",
  false_val: "False",
  Choose: "Choose",
  Printer_Encoding: "Printer Encoding",
  Column_width: "Column Width",
  Recommended_resolutions: "Recommended resolutions",
  Username: "Username",
  No_Products: "No Products",
  Rank: "Rank",
  Rank_label: "Display Rank",
  Rank_product_field_hint: "Display order of product in the Menu",
  Rank_error_message: "The field 'Rank' cannot be 0 or less",
  Price_invalid_msg: "This price is invalid",
  Name_invalid_msg: "The name cannot be empty",
  Payment_method: "Payment Method",
  Cash: "Cash",
  Card: "Card",
  Payment_method_enabled: "Define payment Method",
  Receipt_groups: "Receipt Groups",
  Receipt_group: "Group",
  Net_Sales: "Net Sales",
  Last_30_days: "Last 30 days",
  Cancel_Gift: "Cancel / Gift",
  Discount: "Discount",
  Cancellation_reason: "Cancellation reason",
  Gift: "Gift",
  Gift_order: "Gift order",
  Cancel_order: "Cancel order",
  Gift_reason: "Gift reason",
  Enter_gift_reason: "Gift reason (optional)",
  Enter_cancellation_reason: "Cancellation reason (optional)",
  Gift_confirmation_message: "Are you sure you want to gift products of value:",
  Discard: "Discard",
  Submit: "Submit",
  Reload: "Reload",
  Reloading: "Reloading",
  Average_Revenue_Per_Customer: "Avg Per Customer",
  Gifted_Orders: "Gifted",
  Amount_of_customers: "Amount of customers",
  Customers_of_new_order_msg: "Amount of customers related to order",
  Customers: "Customers",
  Define_customers_per_order: "Define customers per order",
  Define_discounts_enabled: "Define discounts enabled",
  Key_metrics: "Key Metrics",
  Key_metrics_subtitle: "Key Metrics for your business",
  Quantity_support: "Quantity support",
  Pending_Orders: "Pending Orders",
  Pending_Revenue: "Pending Orders Revenue",
  No_Categories_found: "No Categories found",
  Total_Discount: "Discount",
  Before_discount: "Before discount",
  Translation_cannot_be_negative: "The discount amount cannot be negative",
  Translation_cannot_be_larger_than_order: "Discount cannot be greater than order total",
  Discounted_Orders: "Discounted",
  discounted: "Discounted",
  cancelled: "Cancelled",
  pending: "Pending",
  payed: "Payed",
  gifted: "Gifted",
  Is_open_table_printer: "Prints Open Table Receipt",
  Print_Receipts: "Print Receipts",
  Print_Receipts_on_order_submission: "On order submission",
  Print_Receipts_on_order_payment: "On order payment",
  Error: "Error",
  Invoice_submission_error: "Invoice error",
  Try_again: "Try again",
  Reprint: "Re-print",
  Print: "Print",
  Internal: "Internal",
  OpenTable: "Open Table",
  opentable: "Open Table Invoice",
  OpenTable_invoice: "Open Table Invoice",
  GR_Receipt: "GR Receipt",
  Mydata_status: "MyData Status",
  mydata_status_sent: "Sent",
  mydata_status_not_sent: "Not Sent",
  mydata_status_sent_with_error: "Sent with inability to connect with vendor",
  Error_fetching_settings_msg: "Error while fetching settings. Please refresh.",
  Category_invalid_msg: "The category cannot be empty",
  Return_inventory: "Return to inventory",
  Cancel_inventory_message:
    "Some of the products you want to cancel have inventory. What should be done with the inventory after cancel?",
  Cancellation_Mark: "Cancellation Mark",
  Cancellation_UID: "Cancellation UID",
  Cancellation_Authcode: "Cancellation Authcode",
  Receipt_id: "Receipt ID",
  Cancel_receipt_id: "Cancel Receipt ID",
  Resubmit: "Resubmit",
  Exceeded_inventory_for_product: "Exceeded inventory for product",
  Submit_invoice: "Submit Invoice",
  Inventory_invalid_msg: "The inventory quantity cannot be negative or empty",
  Internal_receipt_settings: "Receipt Settings",
  Internal_receipt_setting: "Internal Receipt",
  Table_filter_msg: "Tables filter",
  Select_categories: "Select categories",
  Calculate_change: "Calculate change",
  Enter_cash: "Enter cash",
  Change: "Change",
  Print_scenarios: "Print Scenarios",
  No_print_scenarios_msg: "No print scenarios found",
  New_Scenario: "New scenario",
  Edit_Scenario: "Edit scenario",
  Printers_Settings: "Printers settings",
  Setting: "Setting",
  Active_sub: "Active",
  Inactive_sub: "Inactive",
  Search: "Search",
  Search_product: "Search product",
  Print_font_size: "Print font size",
  No_results_found: "No results found",
  Table_Summary: "Table Summary",
  Table_Summary_print_error: "Error while printing Table Summary",
  Table_Summary_print_success: "Table Summary printed successfully",
  Revenue: "Revenue",
  Sales_per_product: "Sales per product",
  Sales_per_category: "Sales per category",
  Show_label: "Display",
  Discount_reason: "Discount reason",
  Invoiced_Revenue: "Invoiced Revenue",
  Submit_All_Orders: "Submit All Orders",
  Submit_All_Orders_Warning: "Are you sure you want to activate the panic button?",
  manual_cloud_cash_register_enabled_label: "Manual cash register",
  wholesale: "Wholesale",
  retail: "Retail",
  open_table: "Open Table",
  Notification: "Notification",
  Notification_SENT_SUCCESSFULLY: "Notification sent successfully",
  Notification_permission_not_granted: "Notification permission is not granted please follow",
  Ping_From: "You are pinged from",
  Notify_Title: "You are pinged",
  SELECT_USER_NOTIFY: "Select users to notify",
  SELECT_ALL: "Select all",
  Notify: "Notify",
  Subscribe: "Subscribe",
  Series: "Series",
  Invoice_sent: "Invoice sent",
  Without_Invoice: "Without invoice",
  Invoice_from_parent_msg: "Sent from parent order",
  Invoice_Type: "Invoice Type",
  cancelled_invoice: "Cancelled Invoice",
  helperWholesale: "Helper Wholesale",
  error_invoice: "Invoice with error",
  Guides: "Guides",
  Guides_for_notifications: "Guides for user notifications",
  Notification_guides_subtitle:
    "In order to enable user notifications feature in DitoApp, you will need to take 2 actions.",
  Save_as_app_in_phone: "Save DitoApp as an app in your phone",
  Mobile_phone: "Mobile phones",
  Computer: "Computer",
  Allow_notifs_in_browser: "Allow Notifications to Dito App in your browser",
  Allow_notifs_in_phone: "Allow Notifications to Dito App in your mobile phone",
  How_to_save_as_app:
    "Bellow you can find guidelines on how to save Dito as an app in your phone, depending on your Operating System",
  Normal_notifs_allow_case:
    "Normally after you save Dito App in your phone and open it, you will be asked by default to allow notifications upon login",
  Notifs_fallback_case:
    "If this fails for any reason or you accidentally decline, you can find guidelines on how to manually allow notifications for Dito App in your phone, depending on your Operating System",
  Logout_request_message_notifs:
    "When you follow the aforementioned guidelines please logout and login again, and you should be able to see the icon bellow",
  guides_to_enable_notifs: "the guides to enable them",
  additional_notes: "Additional notes",
  no_macos_compatibility_msg:
    "In the current version of the feature, receiving notifications on MacOs computers is not supported",
  available_notif_users_msg: 'The users available to receive notifications are of type "staff" and logged in',
  no_notifs_logout_msg:
    "If a user does not want to be available for receiving notifications (e.g. out of shift) can logout",
  refresh_if_no_user_msg:
    'In case a logged in "staff" user is not displayed as available for notifications, please refresh your page',
  VERSION_ERROR: "A new version of the application is available. Please restart your browser",
  Course_stages: "Course stages",
  Course_stage: "Course stage",
  Course_stages_msg_success: "Successful sending of Course Stage notification",
  Is_course_stages_printer: "Prints course stages",
  Is_fee: "Goverment fee",
  Select_Receipt_Type: "Select Receipt Type",
  Hide_total_on_receipt: "Hide total on receipt",
  Cash_register_management: "Cash register",
  No_pos_terminals_msg: "No POS terminals found",
  New_pos_terminal: "New terminal",
  Edit_pos_terminal: "Edit terminal",
  Allow_print_sound_notif: "Sound notification",
  Tip: "Tips",
  Total_Tip: "Total tips",
  Failed_Find_Vat: "Failed to find customer with corresponding vat number",
  Transform_pay_card: "Transform to card",
  Transform_pay_cash: "Transform to cash",
  Refresh_analytics: "Refresh abalytics",
  Bank_statement: "Bank statement",
  Order_type_external_src: "Order type",
  Close_with_card: "Without integration",
  Select_30_day_period: "Please select only a 15-day period",
  add_extra_top_space: "Extra top space",
  add_extra_bottom_space: "Extra bottom space",
};
